<template>
  <div class="container-lg">
    <!-- Heading -->
    <div class="row">
      <div class="col-md-12">
        <h5 class="float-start">Registered Professionals</h5>
        <button type="button" class="btn btn-outline-danger float-end" :disabled="show_invite" @click="showInvite()">
          Invite Professional
          <i class="fas fa-plus"></i>
        </button>
      </div>
    </div>

    <!-- Content -->
    <div ref="prof-list-container" class="row mt-3">
      <div class="col-md-9">
        <table class="table table-small align-middle table-borderless table-striped mb-0 lh-sm">
          <thead class="bg-sky text-white">
            <tr>
              <th></th>
              <th>Name</th>
              <th>Email</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="user in professionals" :key="user.id" class="diversion-row">
              <td>
                <div class="circle-profile shadow me-3" :style="`background-image: url(${profilePicSrc(user.id)});`">
                </div>
              </td>
              <td>{{ user.firstName }} {{ user.lastName }}</td>
              <td>
                {{ user.email }}
              </td>
              <td>
                <div v-if="toBeDeleted != user.id">
                  <button type="button" class="btn btn-sm" @click="toBeDeleted = user.id">
                    <i class="fas fa-trash-alt"></i>
                  </button>
                </div>
                <div v-else class="btn-group">
                  <button type="button" class="btn btn-sm btn-danger" @click="disableUser(user.id)">
                    Delete
                  </button>
                  <button type="button" class="btn btn-sm btn-secondary ms-2" @click="toBeDeleted = 0">
                    Cancel
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="col-md-3">
        <form v-if="show_invite" @submit.prevent="sendInvite">
          <div class="form-floating mb-3">
            <input id="firstName" v-model="invitee.firstNames" type="Text" :class="{
              'form-control': true,
              'is-invalid': !firstNameChk,
            }" placeholder="First Name" @input="firstNameChk = true" />
            <label for="firstName">First Name</label>
          </div>
          <div class="form-floating mb-3">
            <input id="lastName" v-model="invitee.lastName" type="Text" :class="{
              'form-control': true,
              'is-invalid': !lastNameChk,
            }" placeholder="Last Name" @input="lastNameChk = true" />
            <label for="lastName">Last Name</label>
          </div>
          <div class="form-floating mb-3">
            <input id="email" v-model="invitee.email" type="email" :class="{
              'form-control': true,
              'is-invalid': !emailChk,
            }" placeholder="Email Address" @input="emailChk = true" />
            <label for="email">Email Address</label>
          </div>
          <div class="text-end">
            <button type="button" class="btn btn-secondary" @click="cancelInvite">
              Cancel
            </button>
            <button type="submit" class="btn btn-success  ms-2" :disabled="!isValid">
              Submit
            </button>
          </div>
        </form>
        <div v-if="show_success" class="alert alert-success mt-3">
          Invite successfully sent
        </div>
        <div v-if="show_err" class="alert alert-danger mt-3">
          {{ err_msg }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, inject, ref, onMounted } from 'vue';

export default {
  setup() {
    const api = inject('api').adminApi();
    const loading = inject('$loading');

    const ProfListContainer = ref(null);
    const professionals = ref([]);
    const show_invite = ref(false);
    const show_err = ref(false);
    const err_msg = ref('');
    const show_success = ref(false);
    const invitee = ref({});
    const firstNameChk = ref(true);
    const lastNameChk = ref(true);
    const emailChk = ref(true);

    const toBeDeleted = ref(0);

    const refresh = async () => {
      const loader = loading.show({
        container: ProfListContainer.value,
        isFullPage: false,
      });

      const response = await api.getProfList();
      professionals.value = response.data;

      loader.hide();
    };

    onMounted(async () => {
      await refresh();
    });

    const isValid = computed(() => {
      return firstNameChk.value && lastNameChk.value && emailChk.value;
    });

    const profilePicSrc = (pid) => {
      return `${process.env.VUE_APP_SERVER_URL}/api/user/pic/${pid}`;
    };

    const showInvite = () => {
      invitee.value = {};
      show_err.value = false;
      show_success.value = false;
      show_invite.value = true;
    };

    const cancelInvite = () => {
      show_invite.value = false;
      show_err.value = false;
      show_success.value = false;

      firstNameChk.value = true
      lastNameChk.value = true
      emailChk.value = true
    };

    const sendInvite = async () => {
      //reset error/success messages for previous form submissions
      show_err.value = false;
      err_msg.value = ''
      show_success.value = false;

      validateForm();
      if (!isValid.value) return;
      const loader = loading.show({
        container: ProfListContainer.value,
        isFullPage: false,
      });
      try {
        await api.inviteProf(invitee.value);
        invitee.value = {};
        show_success.value = true;
      } catch (error) {
        err_msg.value = error.data.detail;
        show_err.value = true;
      }
      loader.hide();
      refresh();
    };

    const validateForm = () => {
      firstNameChk.value =
        invitee.value &&
        invitee.value.firstNames &&
        invitee.value.firstNames.length > 0;
      lastNameChk.value =
        invitee.value &&
        invitee.value.lastName &&
        invitee.value.lastName.length > 0;
      emailChk.value =
        invitee.value && invitee.value.email && invitee.value.email.length > 0;
    };

    const disableUser = async (id) => {
      const loader = loading.show({
        container: ProfListContainer.value,
        isFullPage: false,
      });

      await api.disableProf(id);
      toBeDeleted.value = 0;

      loader.hide();
      refresh();
    };

    return {
      'prof-list-container': ProfListContainer,
      isValid,
      professionals,
      show_invite,
      show_err,
      err_msg,
      show_success,
      invitee,
      firstNameChk,
      lastNameChk,
      emailChk,
      toBeDeleted,
      profilePicSrc,
      showInvite,
      cancelInvite,
      sendInvite,
      disableUser,
    };
  },
};
</script>
