<template>
  <div class="container-lg">
    <div class="row mb-4">
      <div class="col">
        <div
          class="container-lg bg-white border border-mint rounded shadow mb-4"
        >
          <div class="row align-items-center py-2">
            <div class="col-md-6 col-lg-5 col-xl-4 mb-3 mb-md-0">
              <h3>Website Administration</h3>
            </div>
            <div class="col text-center d-flex">
              <button
                type="button"
                class="
                  d-flex
                  flex-column
                  p-3
                  btn btn-outline-primary
                  w-25
                  align-items-center
                "
                :class="{ active: currView == 'admin-profs' }"
                @click="goToProfs"
              >
                <h6>Professionals</h6>
                <h2><i class="fas fa-id-card-alt"></i></h2>
              </button>
              <button
                type="button"
                class="
                  ms-1
                  d-flex
                  flex-column
                  p-3
                  btn btn-outline-primary
                  w-25
                  align-items-center
                "
                :class="{ active: currView == 'admin-admins' }"
                @click="goToAdmins"
              >
                <h6>Administrators</h6>
                <h2><i class="fas fa-users"></i></h2>
              </button>
            </div>
          </div>
        </div>
        <div class="row">
          <router-view></router-view>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';

export default {
  setup() {
    const route = useRoute();
    const router = useRouter();

    const currView = computed(() => {
      return route.name;
    });

    const goToProfs = () => {
      router.push({ name: 'admin-profs' });
    };

    const goToAdmins = () => {
      router.push({ name: 'admin-admins' });
    };

    return {
      currView,
      goToProfs,
      goToAdmins,
    };
  },
};
</script>
